<template>
 <div class="index-m" style="height: 100vh;background: #f3f3f3;">
   <div>
     <img :src="require('@/assets/images/20240516150841.jpg')"  style="width: 100%;" /> 
   </div>
 </div>
 
 
 <view class="exit-login" @click="exitHandle">退出</view>


</template>

<script>
import { showToast, NoticeBar, showLoadingToast, closeToast } from "vant";
import { getCookie } from "../util";
import {setCookie } from "../util";
import {showConfirmDialog } from "vant";
//import GoodsList from "../components/GoodsList.vue";

export default {
  components: {
    //GoodsList,
  },
  data() {
    return {
      // banners: [
      //   require("@/assets/images/product/b1.jpg"),
      //   require("@/assets/images/product/b2.jpg"),
      //   require("@/assets/images/product/b3.jpg"),
      //   require("@/assets/images/product/b4.jpg"),
      //   require("@/assets/images/product/b5.jpg"),
      //   require("@/assets/images/product/b6.jpg"),
      //   require("@/assets/images/product/b7.jpg"),
      // ],
	  banners:[],
      pcbanners: [
        require("@/assets/images/pc_index_banner_01.jpg"),
        require("@/assets/images/pc_index_banner_02.jpg"),
        require("@/assets/images/pc_index_banner_03.jpg"),
        require("@/assets/images/pc_index_banner_04.jpg"),
      ],
      announce: [],
      categorys: [],
      cateid: 0,
      loading: false,
      goods: [],
      videos: [],
      pdts: [],
	  logo:"",
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }
	

    this.rootApp.use(NoticeBar);

    this.SYSLANG = this.LANG.system;
    this.GOODSLANG = this.LANG.pages.goods;
    this.UPLOADBASE = this.uploadbase;
    this.init();
  },
  methods: {
	  exitHandle(){
	    showConfirmDialog({
	      title: this.SYSLANG.dialogtitle,
	      message: "確定退出重新登錄嘛",
	      confirmButtonText: this.SYSLANG.queding,
	      cancelButtonText: this.SYSLANG.quxiao,
	    })
	      .then(() => {
	        //退出登录
	        setCookie("agentloginid", "");
	        setCookie("agentlogintoken", "");
	        this.$router.push("/login");
	      })
	      .catch(() => {});
	  },
    init() {
    
      this.axios.get(this.actions.memberBrandStatus).then((response) => {
        let { code, data } = response.data;
        if (code == "200" && data.levelType==2) {
            this.$router.replace({
                        path: "/",
            });
        } 
      });
    },
	
    viewAnnounce(id) {
      if (isNaN(id * 1)) {
        return;
      }

      this.$router.push({
        path: "/announce",
        query: {
          id,
        },
      });
    },
    changeCate() {
      this.goods = [];
      this.getGoodsList();
    },
    getGoodsList() {
      this.loading = true;

      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.axios
        .post(this.actions.goods_list, {
          cateid: this.cateid * 1,
          curpage: 1,
          pagesize: 8,
        })
        .then(({ data }) => {
          closeToast();
          let { code, msg, goods } = data;

          if (code == "SUCCESS") {
            let { list } = goods;
            this.goods = this.goods.concat(list);
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }

          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
.van-notice-bar::before {
  content: var(--aog-gg-text);
  margin-right: 4px;
}
.notice-swipe >>> .van-swipe-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.exit-login {
       display: flex;
       justify-content: center;
       align-items: center;
       width: 50px;
       height: 50px;
       background-color: var(--van-card-price-color);
       color: #fff;
       font-size: 14px;
       border-radius: 50%;
       position: fixed;
       right: 16px;
       bottom: calc(var(--van-tabbar-height) + 16px);
   }	

@media (max-width: 768px) {
  .index-pc {
    display: none;
  }

  .my-video {
    width: 100vw;
    height: 45.5vw;
    background-color: #000;
  }

  .logo {
    display: flex;
    width: 100vw;
    height: 60vw;
    justify-content: center;
    align-items: center;
  }

  .logo > img {
    width: 60%;
  }
  .my-swipe {
    width: 100vw;
    font-size: 0;
    --van-swipe-indicator-size: 8px;
    --van-swipe-indicator-inactive-background: #fff;
    --van-swipe-indicator-active-background: rgba(0, 0, 0, 0);
    --van-swipe-indicator-inactive-opacity: 1;
    --van-swipe-indicator-margin: 15px;
  }
  .my-swipe >>> .van-swipe__indicator--active {
    position: relative;
  }
  .my-swipe >>> .van-swipe__indicator--active::before,
  .my-swipe >>> .van-swipe__indicator--active::after {
    content: "";
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
  }
  .my-swipe >>> .van-swipe__indicator--active::before {
    width: 6px;
    height: 6px;
    margin-top: -3px;
    margin-left: -3px;
    background-color: #000;
  }
  .my-swipe >>> .van-swipe__indicator--active::after {
    width: 14px;
    height: 14px;
    top: -3px;
    left: -3px;
    border: 1px solid #000;
  }
  .my-swipe >>> .van-swipe__indicator:not(:last-child) {
    margin-right: 12px;
  }
}

@media (min-width: 769px) {
	
   	
	
	
  .index-m {
    display: none;
  }
  .index-pc .gg-layout {
    padding: 0 calc(100vw / 1920 * 360);
    position: relative;
    height: calc(100vw / 1920 * 40);
    overflow: hidden;
  }
  .index-pc .gg-layout .van-notice-bar {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin-left: 0;
    font-size: calc(100vw / 1920 * 14);
    --van-notice-bar-padding: 0;
  }
  .index-pc .gg-layout >>> .van-swipe {
    cursor: pointer;
  }
  .index-pc .gg-layout >>> .van-swipe:hover {
    color: var(--van-card-price-color);
  }
  .my-swipe {
    width: 100%;
  }
  .my-swipe .el-carousel {
    --el-carousel-indicator-width: 8px;
    --el-carousel-indicator-height: 8px;
    --el-carousel-indicator-padding-horizontal: 19px;
  }
  .my-swipe >>> .el-carousel .el-carousel__button {
    opacity: 1;
    border-radius: 50%;
    position: relative;
  }
  .my-swipe >>> .el-carousel .is-active .el-carousel__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }
  .my-swipe >>> .el-carousel .is-active .el-carousel__button::before,
  .my-swipe >>> .el-carousel .is-active .el-carousel__button::after {
    content: "";
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
  }
  .my-swipe >>> .el-carousel .is-active .el-carousel__button::before {
    width: 6px;
    height: 6px;
    background-color: #000;
  }
  .my-swipe >>> .el-carousel .is-active .el-carousel__button::after {
    width: 14px;
    height: 14px;
    border: 1px solid #000;
  }
  .index-category {
    padding: calc(100vw / 1920 * 40) calc(100vw / 1920 * 360);
    background-color: #f7f7f7;
  }
  .index-category .content {
    display: flex;
    justify-content: space-between;
  }
  .index-category .content .c {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .index-category .cates {
    padding-top: calc(100vw / 1920 * 36);
    height: calc(100vw / 1920 * 44);
    font-size: 20px;
    color: #000;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .index-category .cates >>> .el-tabs__header {
    margin-bottom: 0;
    --el-tabs-header-height: calc(100vw / 1920 * 44);
    --el-border-color-light: rgba(0, 0, 0, 0);
    --el-font-size-base: 16px;
    --el-text-color-primary: #666;
    --el-color-primary: #000;
  }
  .index-category .index-goods {
    padding-top: calc(100vw / 1920 * 28);
  }
  .index-video-block {
    padding-top: calc(100vw / 1920 * 40);
  }
  .index-video-block .my-video {
    width: 100%;
    height: calc(100vw / 1920 * 545);
  }
}
</style>
